// PACKAGE
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// ASSETS
import { SlideClient1, SlideClient2, SlideClient3, SlideClient4, SlideClient5, SlideClient6, SlideClient7, SlideClient8, MapClient } from "../assets";

const ContentClient = () => {
    return (
        <div id="client-cuso" className="client-cuso flex h-screen items-center justify-center">
            <div className="flex flex-col">
                <div className="flex justify-center">
                    <img src={MapClient} className="w-full md:w-[80%] lg:w-[70%] xl:w-[60%]"></img>
                </div>
                <Carousel showArrows={true} infiniteLoop={true} autoPlay={true} showThumbs={false} showStatus={false} showIndicators={false} swipeable={true} emulateTouch={true}>
                    <div>
                        <img src={SlideClient1} />
                    </div>
                    <div>
                        <img src={SlideClient2} />
                    </div>
                    <div>
                        <img src={SlideClient3} />
                    </div>
                    <div>
                        <img src={SlideClient4} />
                    </div>
                    <div>
                        <img src={SlideClient5} />
                    </div>
                    <div>
                        <img src={SlideClient6} />
                    </div>
                    <div>
                        <img src={SlideClient7} />
                    </div>
                    <div>
                        <img src={SlideClient8} />
                    </div>
                </Carousel>
            </div>
        </div>
    )
};

export default ContentClient;