// ASSETS
import { IconWA, Bullet } from "../assets";

const ContentFAQ = () => {

    return (
        <div className="faq-content flex h-screen items-center justify-center">
            <div className="flex flex-col">
                <div>
                    <h1 id="faq" className="font-bold text-[#26579B] text-[30px] sm:text-[30px] md:text-[35px] xl:text-[50px] text-center">FAQ</h1>
                </div>
                <div className="flex flex-col md:flex-row">
                    {/* CONTENT */}
                    <div className="p-4 flex-col">
                        <div>
                            <div className="flex">
                                <img src={Bullet} alt="bullet" className="w-[10px] h-[10px] mt-[9px] sm:mt-[10px] xl:mt-[11px] mr-[4px]" />
                                <p className="font-bold text-[16px] sm:text-[18px] xl:text-[20px]">Berapa Lama Proses Migrasi CUSO?</p>
                            </div>
                            <p className="text-[13px] sm:text-[14px] xl:text-[16px] text-justify ml-[15px]">Kekhawatian ini sering sekali jadi kendala/keraguan koperasi untuk bertransformasi. Proses migrasi kami membutuhkan waktu singkat, hanya 10 hari kerja.</p>
                        </div>
                        <div className="mt-[8px]">
                            <div className="flex">
                                <img src={Bullet} alt="bullet" className="w-[10px] h-[10px] mt-[9px] sm:mt-[10px] xl:mt-[11px] mr-[4px]" />
                                <p className="font-bold text-[16px] sm:text-[18px] xl:text-[20px]">Berapa Harga CUSO?</p>
                            </div>
                            <p className="text-[13px] sm:text-[14px] xl:text-[16px] text-justify ml-[15px]">Dengan ekosistem yang kami jamin relevan, serta komitmen dan misi kami dalam membangun Gerakan Koperasi Indonesia. Mekanisme harga dan layanan kami adalah yang paling menarik di pasar.</p>
                        </div>
                        <div className="mt-[8px]">
                            <div className="flex">
                                <img src={Bullet} alt="bullet" className="w-[10px] h-[10px] mt-[9px] sm:mt-[10px] xl:mt-[11px] mr-[4px]" />
                                <p className="font-bold text-[16px] sm:text-[18px] xl:text-[20px]">Bagaimana Laporan Keuangannya?</p>
                            </div>
                            <p className="text-[13px] sm:text-[14px] xl:text-[16px] text-justify ml-[15px]">Laporan keuangan adalah output utama dalam sebuah proses Administrasi Koperasi. Bukan hanya rapih, tapi juga otomatis tanpa harus diolah lagi di Excel.</p>
                        </div>
                    </div>
                    <div className="p-4 flex-col">
                        <div className="-mt-[20px] md:-mt-0">
                            <div className="flex">
                                <img src={Bullet} alt="bullet" className="w-[10px] h-[10px] mt-[9px] sm:mt-[10px] xl:mt-[11px] mr-[4px]" />
                                <p className="font-bold text-[16px] sm:text-[18px] xl:text-[20px]">Apakah Data Koperasi Aman?</p>
                            </div>
                            <p className="text-[13px] sm:text-[14px] xl:text-[16px] text-justify ml-[15px]">Kami Menjamin kerahasiaan serta keamanan data para “Dulur CUSO”. Pada poin ini, kami tidak pernah merasa puas dengan apapun yang kami capai hari ini, Dengan melakukan riset terus menerus. Karena kami paham, keamanan system itu dinamis bukan statis.</p>
                        </div>
                        <div className="mt-[8px]">
                            <div className="flex">
                                <img src={Bullet} alt="bullet" className="w-[10px] h-[10px] mt-[9px] sm:mt-[10px] xl:mt-[11px] mr-[4px]" />
                                <p className="font-bold text-[16px] sm:text-[18px] xl:text-[20px]">Apakah Bisa Kustom?</p>
                            </div>
                            <p className="text-[13px] sm:text-[14px] xl:text-[16px] text-justify ml-[15px]">Yup, sangat bisa. Karena kami memahami karakteristik koperasi - koperasi di Indonesia berbeda-beda. Hal itu berarti kebutuhannya juga bisa berbeda.</p>
                        </div>
                        <div className="mt-[8px]">
                            <div className="flex">
                                <img src={Bullet} alt="bullet" className="w-[10px] h-[10px] mt-[9px] sm:mt-[10px] xl:mt-[11px] mr-[4px]" />
                                <p className="font-bold text-[16px] sm:text-[18px] xl:text-[20px]">Apakah Mobile Appsnya Bisa Menggunakan Nama Koperasi?</p>
                            </div>
                            <p className="text-[13px] sm:text-[14px] xl:text-[16px] text-justify ml-[15px]">Yup, Pastinya dong. Ini merupakan salah satu dari sekian hal yang kami pahami dari Koperasi yaitu kebutuhan mengenai branding dan kemelekatan/faktor emosional koperasi dengan anggotanya.</p>
                        </div>
                    </div>
                    <div className="p-4 flex-col">
                        <div className="-mt-[20px] md:-mt-[6px]">
                            <p className="text-[20px] sm:text-[22px] xl:text-[24px] text-[#26579B] font-bold">Any Question About CUSO?</p>
                        </div>
                        <div className="mt-[4px]">
                            <p className="text-[13px] sm:text-[14px] xl:text-[16px] text-justify font-bold">Lebih dari 20 tahun CUSO mengkurasi dan mengembangkan layanan agar serelevan mungkin dengan kebutuhan yang ada di koperasi.</p>
                        </div>
                        <div className="mt-[4px]">
                            <p className="text-[10px] sm:text-[11px] xl:text-[12px] text-justify">Kami berani mengatakan, ekosistem yang kami kembangkan sangat sesuai dengan kebutuhan koperasi - koperasi di Indonesia, dibandingkan dengan perusahaan Aplikasi Koperasi lain nya.</p>
                        </div>
                        <div className="mt-[8px] flex justify-center">
                            <a href="https://wa.me/6281211694801" target="_blank" rel="noreferrer">
                                <button className="bg-[#118B3D] p-2 rounded-lg md:w-[200px] lg:w-[350px]">
                                    <div className="flex justify-center">
                                        <p className="text-white text-[16px] font-bold mr-[4px]">WHATSAPP CUSO</p>
                                        <img src={IconWA} alt="icon-wa" className="w-[20px] h-[20px] mt-[2px]" />
                                    </div>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ContentFAQ;


