// PACKAGE
import { useState } from "react";
// COMPONENTS
import { SidebarOverlay } from "./index";

const Navbar = () => {
    // DATA
    const [navList] = useState([{ id: "#home", list: "Home" }, { id: "#about-cuso", list: "About CUSO" }, { id: "#ekosistem-cuso", list: "CUSO Ecosystem" }, { id: "#implementasi", list: "Implementation" }, { id: "#client-cuso", list: "Our Client" }, { id: "#faq", list: "FAQ" }]);
    // CONDITIONAL
    const [showSidebar, setShowSidebar] = useState(false);

    return (
        <div className="Navbar">
            {/* NAVBAR */}
            <nav className="absolute inset-x-0 top-0 bg-white border-gray-200 shadow-xl">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-[4px]">
                    {/* HEADER */}
                    <a href="#home" className="flex items-center">
                        <span className="text-[#26579B] self-center text-[35px] font-extrabold">CUSO</span>
                    </a>

                    {/* BUTTON MOBILE */}
                    <button data-collapse-toggle="navbar-default" data-target="#navbar-default" aria-controls="navbar-default" aria-expanded="false" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" onClick={() => setShowSidebar(!showSidebar)}>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="#26579B" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>

                    {/* NAVBAR LIST */}
                    <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                        <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0">
                            {navList.map((e, idx) => (
                                <li key={idx}>
                                    <a href={e.id} className="block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">{e.list}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </nav>

            {/* SIDEBAR */}
            <SidebarOverlay navList={navList} showSidebar={showSidebar} />
        </div>
    )
};

export default Navbar;