const Footer = () => {
    return (
        <div className="footer mt-[50px] md:mt-[100px] bg-[#26579B] h-[250px] w-full">
            <div className="flex justify-center p-[20px]">
                <div className="flex mx-auto">
                    <div className="flex flex-col w-[200px] sm:w-[250px] md:w-[350px]">
                        <p className="text-white font-bold text-[16px] md:text-[20px]">CUSO Digital Indonesia</p>
                        <p className="text-white text-[12px] md:text-[14px]">MULA by Galeria Jakarta</p>
                        <p className="text-white text-[12px] md:text-[14px]">Jl. TB Simatupang No.17, RT.6/RW.9, Cilandak Bar., Kec. Cilandak, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12430.</p>
                        <p className="text-white font-semibold mt-[6px] text-[11px] md:text-[14px]">Call Center: <a href="https://wa.me/6281211694801" target="_blank" className="underline hover:text-blue-300">0812-1169-4801</a></p>
                        <p className="text-white font-semibold text-[11px] md:text-[14px]">Customer Care: <a href="mailto:cs@cusoindonesia.id" target="_blank" className="underline hover:text-blue-300">cs@cusoindonesia.id</a></p>
                    </div>
                    {/* DIVIDER */}
                    <div className="w-[1px] h-[160px] border-opacity-[60%] bg-white mr-[12px]"></div>
                    <div className="flex flex-col sm:w-[150px] md:w-[250px]">
                        <p className="text-white font-bold text-[16px] md:text-[20px]">Follow Us</p>
                        <a href="https://instagram.com/cusoindonesia?igshid=MzRlODBiNWFlZA==" target="_blank" className="text-white text-[12px] md:text-[14px] hover:text-blue-300 hover:underline">Instagram</a>
                        <a href="https://www.facebook.com/cuso.ind?mibextid=ZbWKwL" target="_blank" className="text-white text-[12px] md:text-[14px] hover:text-blue-300 hover:underline">Facebook</a>
                        <a href="https://youtube.com/@cusoindonesia?si=ffV2-K5_YBi5UDe1" target="_blank" className="text-white text-[12px] md:text-[14px] hover:text-blue-300 hover:underline">Youtube</a>
                    </div>
                </div>
            </div>
            <div>
                <p className="font-semibold text-[10px] text-white text-center mt-[20px]">© PT. CUSO Digital Indonesia, {new Date().getFullYear()}</p>
            </div>
        </div>
    )
};

export default Footer;