// ASSETS
import { YTIcon, FBIcon, IGIcon } from "../assets";

const SidebarOverlay = ({ navList, showSidebar }) => {
    return (
        <div className="sidebar-overlay shadow-2xl">
            <div className={`absolute right-0 top-[68px] bg-white shadow-2xl rounded-b-lg rounded-t-none overflow-hidden ${showSidebar ? "duration-150 ease-out w-[200px]" : "duration-150 ease-out w-0"}`}>
                <ul className="font-medium flex flex-col p-4 md:p-0 mt-[8px] md:flex-row md:space-x-8 md:mt-0 md:border-0">
                    {navList.map((e, idx) => (
                        <li key={idx}>
                            <a href={e.id} className="text-right block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">{e.list}</a>
                        </li>
                    ))}
                    <li>
                        <div className="flex justify-end">
                            <div className="border border-[#274F66] w-[100px] mt-[50px] text-right mr-[10px]" />
                        </div>
                    </li>
                    <li>
                        <div className="flex justify-end">
                            <div className="flex mr-[10px]">
                                <a href="https://www.facebook.com/cuso.ind?mibextid=ZbWKwL" target="_blank"><img src={FBIcon} className="mr-[4px] w-[25px] mt-[5px]" /></a>
                                <a href="https://instagram.com/cusoindonesia?igshid=MzRlODBiNWFlZA==" target="_blank"><img src={IGIcon} className="mr-[4px] w-[25px] mt-[5px]" /></a>
                                <a href="https://youtube.com/@cusoindonesia?si=ffV2-K5_YBi5UDe1" target="_blank"><img src={YTIcon} className="w-[25px] h-[30px] mt-[3px]" /></a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="flex justify-end mt-[3px]">
                            <p className="font-semibold text-[9px]">© PT. CUSO Digital Indonesia, 2023</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
};

export default SidebarOverlay;
