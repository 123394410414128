// PACKAGGE
import { useState } from "react";
// ASSETS
import { AppCRA, AppCore, AppDashboard, AppMobile, AppSPBU, AppPOS, AppTPI, AppEvoting } from "../assets";

const ContentEkosistem = () => {
    // DATA
    const [selectedSub, setSelectedSub] = useState("CCS");

    // HANDLE BUTTON COLOR
    const handleButtonColour = (sub) => {
        if (selectedSub === sub) {
            return "border-[#26579B] text-[#26579B]";
        } else {
            return "border-gray-400 text-gray-400";
        };
    };

    return (
        <div className="ekosistem-cuso flex h-screen items-center justify-center">
            <div className="flex justify-center">
                <div className="flex flex-col">
                    {/* HEADER */}
                    <h1 id="ekosistem-cuso" className="font-bold text-[#26579B] text-[30px] sm:text-[30px] md:text-[35px] xl:text-[50px] text-center p-2">Ekosistem Koperasi Digital CUSO</h1>

                    {/* BUTTON */}
                    <div className="mt-[4px] p-2 grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 justify-center">
                        <button className={`mr-[4px] p-2 lg:p-1.5 mt-[8px] text-[11px] sm:text-[12px] xl:text-[14px] h-auto font-semibold border ${handleButtonColour("CCS")} rounded-xl hover:border-[#26579B] hover:text-[#26579B]`} onClick={() => setSelectedSub("CCS")}>CUSO CORE SYSTEM</button>
                        <button className={`mr-[4px] p-2 lg:p-1.5 mt-[8px] text-[11px] sm:text-[12px] xl:text-[14px] h-auto font-semibold border ${handleButtonColour("CDM")} rounded-xl hover:border-[#26579B] hover:text-[#26579B]`} onClick={() => setSelectedSub("CDM")}>CUSO DASHBOARD MONITORING</button>
                        <button className={`mr-[4px] p-2 lg:p-1.5 mt-[8px] text-[11px] sm:text-[12px] xl:text-[14px] h-auto font-semibold border ${handleButtonColour("CMA")} rounded-xl hover:border-[#26579B] hover:text-[#26579B]`} onClick={() => setSelectedSub("CMA")}>CUSO MOBILE APPS</button>
                        <button className={`mr-[4px] p-2 lg:p-1.5 mt-[8px] text-[11px] sm:text-[12px] xl:text-[14px] h-auto font-semibold border ${handleButtonColour("CRA")} rounded-xl hover:border-[#26579B] hover:text-[#26579B]`} onClick={() => setSelectedSub("CRA")}>CUSO RELATIONSHIP AGENT</button>
                        <button className={`mr-[4px] p-2 lg:p-1.5 mt-[8px] text-[11px] sm:text-[12px] xl:text-[14px] h-auto font-semibold border ${handleButtonColour("CPOS")} rounded-xl hover:border-[#26579B] hover:text-[#26579B]`} onClick={() => setSelectedSub("CPOS")}>CUSO POINT OF SALES</button>
                        <button className={`mr-[4px] p-2 lg:p-1.5 mt-[8px] text-[11px] sm:text-[12px] xl:text-[14px] h-auto font-semibold border ${handleButtonColour("CSPBU")} rounded-xl hover:border-[#26579B] hover:text-[#26579B]`} onClick={() => setSelectedSub("CSPBU")}>CUSO SPBU</button>
                        <button className={`mr-[4px] p-2 lg:p-1.5 mt-[8px] text-[11px] sm:text-[12px] xl:text-[14px] h-auto font-semibold border ${handleButtonColour("CTPI")} rounded-xl hover:border-[#26579B] hover:text-[#26579B]`} onClick={() => setSelectedSub("CTPI")}>CUSO TPI</button>
                        <button className={`mr-[4px] p-2 lg:p-1.5 mt-[8px] text-[11px] sm:text-[12px] xl:text-[14px] h-auto font-semibold border ${handleButtonColour("EVOTE")} rounded-xl hover:border-[#26579B] hover:text-[#26579B]`} onClick={() => setSelectedSub("EVOTE")}>CUSO E-VOTING</button>
                    </div>

                    {/* CONTENT */}
                    {selectedSub === "CCS" ? (
                        <div className="flex justify-center flex-col lg:flex-row p-4">
                            <div className="flex justify-center">
                                <img src={AppCore} alt="ccs-img" className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] xl:w-[450px] lg:mr-[25px]"></img>
                            </div>
                            <div className="flex flex-col w-full lg:w-[600px] mt-[10px]">
                                <p className="font-bold text-[20px] lg:text-[30px] xl:text-[34px]">CUSO CORE SYSTEM</p>
                                <p className="mt-[5px] text-[14px] text-justify lg:text-[16px] xl:text-[18px]"> CUSO CORE SYSTEM merupakan sistem online & real-time untuk setiap transaksi dan jurnalnya, termasuk transaksi antar cabang tersedia proses kliring secara otomatis, sehinggal menghasilkan laporan yang akurat & Real-time.</p>
                            </div>
                        </div>
                    ) : null}
                    {selectedSub === "CDM" ? (
                        <div className="flex justify-center flex-col lg:flex-row p-4">
                            <div className="flex justify-center">
                                <img src={AppDashboard} alt="ccs-img" className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] xl:w-[450px] lg:mr-[25px]"></img>
                            </div>
                            <div className="flex flex-col w-full lg:w-[600px] mt-[10px]">
                                <p className="font-bold text-[20px] lg:text-[30px] xl:text-[34px]">CUSO DASHBOARD MONITORING</p>
                                <p className="mt-[5px] text-[14px] text-justify lg:text-[16px] xl:text-[18px]">Menyajikan informasi mengenai indikator kinerja keuangan, statistik & perkembangan bisnis koperasi. Indikator/diagnosa keuangan dari aktifitas koperasi secara Real-time & divisualisasikan dari data yang telah terkumpul.</p>
                                <p className="mt-[2px] text-[14px] text-justify lg:text-[16px] xl:text-[18px]">Selain itu, CUSO Dashboard Monitoring juga membantu Pengurus, Pengawas & Manajemen dalam membuat strategi untuk mengambil keputusan yang lebih tepat dan juga akurat.</p>
                            </div>
                        </div>
                    ) : null}
                    {selectedSub === "CMA" ? (
                        <div className="flex justify-center flex-col lg:flex-row p-4">
                            <div className="flex justify-center">
                                <img src={AppMobile} alt="cma-img" className="w-[500px] sm:w-[600px] md:w-[650px] xl:w-[700px] lg:h-[225px] xl:h-[275px] lg:mr-[25px]"></img>
                            </div>
                            <div className="flex flex-col w-full lg:w-[600px] mt-[10px]">
                                <p className="font-bold text-[20px] lg:text-[30px] xl:text-[34px]">CUSO MOBILE APPS</p>
                                <p className="mt-[5px] text-[14px] text-justify lg:text-[16px] xl:text-[18px]">MOBILE APPS adalah aplikasi mobile yang merupakan bagian dari CUSO Core yang dikembangkan, dikelola, dan dimiliki oleh PIHAK KESATU (CUSO) dengan platform Mobile News, Mobile Payment, Mobile Wallet, Mobile Commerce, dan Mobile Loyalty.</p>
                            </div>
                        </div>
                    ) : null}
                    {selectedSub === "CRA" ? (
                        <div className="flex justify-center flex-col lg:flex-row p-4">
                            <div className="flex justify-center">
                                <img src={AppCRA} alt="cra-img" className="w-[500px] sm:w-[600px] md:w-[650px] xl:w-[700px] lg:h-[225px] xl:h-[275px] lg:mr-[25px]"></img>
                            </div>
                            <div className="flex flex-col mt-[10px] w-full lg:w-[600px]">
                                <p className="font-bold text-[20px] lg:text-[30px] xl:text-[34px]">CUSO RELATIONSHIP AGENT</p>
                                <p className="mt-[5px] text-[14px] text-justify lg:text-[16px] xl:text-[18px]">CUSO Relationship Agent merujuk pada individu atau entitas yang bertanggung jawab untuk membangun dan merawat hubungan antara sebuah perusahaan atau organisasi dengan pelanggan, mitra bisnis, atau pemangku kepentingan lainnya. Peran seorang relationship agent adalah untuk mengelola hubungan tersebut dengan tujuan meningkatkan kepuasan pelanggan, mempromosikan loyalitas, dan menciptakan nilai jangka panjang bagi perusahaan atau organisasi.</p>
                                <p className="mt-[2px] text-[14px] text-justify lg:text-[16px] xl:text-[18px]">Tujuan utama dari CUSO Relationship Agent  adalah untuk membangun hubungan yang kuat dan saling menguntungkan antara perusahaan atau organisasi dengan pemangku kepentingan utama mereka.</p>
                            </div>
                        </div>
                    ) : null}
                    {selectedSub === "CPOS" ? (
                        <div className="flex justify-center flex-col lg:flex-row p-4">
                            <div className="flex justify-center">
                                <img src={AppPOS} alt="cpos-img" className="w-[500px] sm:w-[600px] md:w-[650px] xl:w-[700px] lg:h-[225px] xl:h-[275px] lg:mr-[25px]"></img>
                            </div>
                            <div className="flex flex-col mt-[10px] w-full lg:w-[600px]">
                                <p className="font-bold text-[20px] lg:text-[30px] xl:text-[34px]">CUSO POINT OF SALES</p>
                                <p className="mt-[5px] text-[14px] text-justify lg:text-[16px] xl:text-[18px]">Aplikasi Toko merupakan perangkat digital yang digunakan untuk menyederhanakan semua alur transaksi dan bisnis pada sebuah toko. Aplikasi ini juga dapat membantu otomatisasi seluruh proses atau manajemen toko seperti akuntansi penjualan, pembelian dan stok barang.</p>
                            </div>
                        </div>
                    ) : null}
                    {selectedSub === "CSPBU" ? (
                        <div className="flex justify-center flex-col lg:flex-row p-4">
                            <div className="flex justify-center">
                                <img src={AppSPBU} alt="cspbu-img" className="w-[500px] sm:w-[600px] md:w-[650px] xl:w-[700px] lg:mr-[25px]"></img>
                            </div>
                            <div className="flex flex-col mt-[10px] w-full lg:w-[600px]">
                                <p className="font-bold text-[20px] lg:text-[30px] xl:text-[34px]">CUSO SPBU</p>
                                <p className="mt-[5px] text-[14px] text-justify lg:text-[16px] xl:text-[18px]">Aplikasi CUSO SPBU adalah sebuah platform untuk mempermudah masyarakat dalam proses penjualan di SPBU. Di dalam platform ini juga kami memberikan fitur Penjualan BBM, Pembelian Stok, Stok Opname, Mutasi Kas Teller, Berkas Permohonan dan Riwayat Penjualan.</p>
                                <p className="mt-[2px] text-[14px] text-justify lg:text-[16px] xl:text-[18px]">Didalam platform ini juga sangat aktual, sehingga pengguna bisa melihat secara detail apa jenis produk nya, berapa stok nya dan juga melihat riwayat penjualan secara detail.</p>
                            </div>
                        </div>
                    ) : null}
                    {selectedSub === "CTPI" ? (
                        <div className="flex justify-center flex-col lg:flex-row p-4">
                            <div className="flex justify-center">
                                <img src={AppTPI} alt="ctpi-img" className="w-[330px] sm:w-[400px] md:w-[450px] xl:w-[500px] lg:mr-[25px]"></img>
                            </div>
                            <div className="flex flex-col mt-[10px] w-full lg:w-[600px]">
                                <p className="font-bold text-[20px] lg:text-[30px] xl:text-[34px]">CUSO TPI</p>
                                <p className="mt-[5px] text-[14px] text-justify lg:text-[16px] xl:text-[18px]">Tempat Pelelangan Ikan, juga dikenal sebagai pasar ikan atau tempat pelelangan perikanan, adalah lokasi fisik di mana ikan dan produk perikanan lainnya dilelang kepada para pembeli. Tempat-tempat ini umumnya terletak di dekat pelabuhan atau wilayah pesisir dan merupakan titik pusat dalam rantai pasokan perikanan.</p>
                                <p className="mt-[2px] text-[14px] text-justify lg:text-[16px] xl:text-[18px]">Kali ini, cuso merilis Aplikasi TPI untuk mempermudah pengguna melihat hasil lelang, untuk menambah jenis ikan, lelang ikan dan banyak hal lain nya yang bisa di lakukan pada Aplikasi ini.</p>
                            </div>
                        </div>
                    ) : null}
                    {selectedSub === "EVOTE" ? (
                        <div className="flex justify-center flex-col lg:flex-row p-4">
                            <div className="flex justify-center">
                                <img src={AppEvoting} alt="ctpi-img" className="w-[330px] sm:w-[400px] md:w-[450px] xl:w-[500px] lg:mr-[25px]"></img>
                            </div>
                            <div className="flex flex-col mt-[10px] w-full lg:w-[600px]">
                                <p className="font-bold text-[20px] lg:text-[30px] xl:text-[34px]">CUSO E-VOTING</p>
                                <p className="mt-[5px] text-[14px] text-justify lg:text-[16px] xl:text-[18px]">CUSO E-Voting merupakan aplikasi pemungutan suara elektronik yang biasanya digunakan untuk pemilihan pengurus & pengawas dalam suatu koperasi yang menggunakan media elektronik.</p>
                                <p className="mt-[2px] text-[14px] text-justify lg:text-[16px] xl:text-[18px]">Hasil dari menggunakan data E-Voting dapat ditampilkan secara real-time dan mengefisiensikan biaya.</p>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
};

export default ContentEkosistem;









