// PACKAGE
import { useState } from "react";
// ASSETS
import { ParameterSetup, MappingData, SystemDevelopment, TestingVertification, Training, Implementation, ReviewOps, SystemDeepening } from "../assets";

const ContentImpelentasi = () => {
    // DATA
    const [content] = useState([{ src: ParameterSetup, name: "Parameter Setup" }, { src: MappingData, name: "Mapping Data" }, { src: SystemDevelopment, name: "System Development" }, { src: TestingVertification, name: "Testing & Verification" },
    { src: Training, name: "Training" }, { src: Implementation, name: "Implementation" }, { src: ReviewOps, name: "Review Ops" }, { src: SystemDeepening, name: "System Deepening" }]);

    return (
        <div className="implementation-content flex h-screen items-center justify-center">
            <div className="flex justify-center bg-gradient-to-b from-[#FFFFFF] via-[#F9FCFD] to-[#E5F1F5]">
                <div className="flex flex-col">
                    {/* HEADER */}
                    <h1 id="implementasi" className="font-bold text-[#26579B] text-[30px] sm:text-[30px] md:text-[35px] xl:text-[50px] text-center p-2">Proses Implementasi Ekosistem Digital</h1>

                    {/* CONTENT */}
                    <div className="mt-[4px] p-4 xl:p-10 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                        {content.map((e, idx) => (
                            <div className="flex justify-center mr-[8px] mt-[15px] mx-0 sm:mx-[100px] md:mx-[80px] lg:mx-[120px]" key={idx}>
                                <div className="flex flex-col ">
                                    <div className="flex justify-center">
                                        <img src={e.src} alt={e.name} className="w-[80px] h-[80px] lg:w-[100px] lg:h-[100px] xl:w-[100px] xl:h-[85px]" />
                                    </div>
                                    <p className="font-bold text-center text-[16px] lg:text-[18px] xl:text-[22px]">{e.name}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ContentImpelentasi;


