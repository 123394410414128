// ASSETS
import { EkosistemKoperasi } from "../assets";

const ContentAboutCUSO = () => {

    return (
        <div className="about-content">
            <div className="flex justify-center">
                <div className={`flex h-screen items-center justify-center flex-col`}>
                    {/* SECTION 1 */}
                    <section className="flex justify-center">
                        <img id="about-cuso" src={EkosistemKoperasi} alt="img-ekosistem" className="w-full md:w-[80%] lg:w-[70%] p-4" />
                    </section>
                    {/* SECTION 2 */}
                    <section className="p-4 -mt-[20px]">
                        <h1 className="font-bold text-[50px] md:text-[60px] xl:text-[70px] text-[#26579B]">CUSO</h1>
                        <h2 className="font-bold text-[18px] text-justify md:text-[25px] lg:text-[30px] xl:text-[40px] -mt-0 md:-mt-[12px] xl:-mt-[20px] text-[#26579B]">Bukan Sekedar Aplikasi tapi Ekosistem Digital Koperasi</h2>
                        <p className="mt-[8px] text-justify text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px]">Sudah 20 tahun lebih CUSO berdedikasi untuk Gerakan Koperasi Digital di Inonesia. Memulai dengan misi bahwa Aplikasi dapat memudahkan koperasi meningkatkan layanan kepada anggota - anggotanya. Nyatanya, fakta memberi tahu kami bahwa tantangannya lebih daripada sekedar aplikasi.</p>
                        <p className="mt-[6px] text-justify text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px]">Berbicara Koperasi, maka kita akan menemukan fakta menarik mengenai Permasalahan - permasalahan SDM, Proses Pembukuan, Laporan Keuangan, Inovasi hingga kehadiran Koperasi sebagai penopang sendi - sendi ekonomi para anggotanya.</p>
                    </section>
                </div>
            </div>
        </div>
    )
};

export default ContentAboutCUSO;


