// COMPONENTS
import { Navbar, Header, ContentAboutCUSO, ContentClient, ContentEkosistem, ContentImplementasi, ContentFAQ, Footer } from "../components";

const HomePage = () => {
    return (
        <div>
            {/* NAVBAR */}
            <nav className="sticky top-0 z-10">
                <Navbar />
            </nav>

            {/* HEADER */}
            <header className="overflow-x-hidden">
                <Header />
            </header>

            {/* CONTENT BODY */}
            <body className="flex justify-center">
                <div className="max-w-[1920px] ">
                    <div className="mt-[100px]">
                        <ContentAboutCUSO />
                    </div>
                    <div className="mt-[100px] bg-slate-50">
                        <ContentEkosistem />
                    </div>
                    <div className="mt-[100px]">
                        <ContentImplementasi />
                    </div>
                    <div className="mt-[100px]">
                        <ContentClient />
                    </div>
                    <div className="mt-[100px]">
                        <ContentFAQ />
                    </div>
                </div>
            </body>

            {/* FOOTER */}
            <footer className="mt-[200px]">
                <Footer />
            </footer>
        </div>
    )
};

export default HomePage;