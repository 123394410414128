// ASSETS
import { EkosistemKoperasi } from "../assets";

const Header = () => {
    return (
        <div id="home" className="header bg-gradient-to-r from-[#091E3A] via-[#2E75D4] to-[#2D9EE0] w-screen h-screen">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <div className="flex flex-col xl:mt-[70px] w-max-[1440px]">
                    <div className="flex flex-col justify-center">
                        <h1 className="-mt-[40px] text-white text-center text-[90px] sm:text-[120px] font-bold">
                            CUSO
                        </h1>
                        <h2 className="text-white text-center text-[25px] sm:text-[40px] font-bold">
                            EKOSISTEM  DIGITAL KOPERASI
                        </h2>
                        <h2 className="mt-[12px] sm:mt-0 text-white text-center text-[10px] sm:text-[16px] font-semibold">
                            Mari Bersinergi serta Berkolaborasi Membangun Ekosistem Koperasi yang Lebih Efektif, Efisien dan Inovatif!
                        </h2>
                    </div>
                </div>
                <div className="flex justify-center mt-[30px]">
                    <img src={EkosistemKoperasi} alt="header-img" className="w-full" />
                </div>
            </div>
        </div>
    )
};

export default Header;